import axios from 'axios'
import Vue from 'vue'
import {
    AuthHelper
} from '../helpers/auth.helper';
import router from '../../router'


export class API {


    constructor(baseUrl) {

        this.loading = false;
        this.__axiosInstance = axios.create({
            baseURL: baseUrl
        });
        this.baseURL = baseUrl;


        this.__axiosInstance.interceptors.request.use(async (config) => {


            if (!this.token || this.token === 'undefined')
                this.token = await AuthHelper.getToken();

            if (this.token === 'undefined') {
                this.token = await AuthHelper.setToken();
                this.token = await AuthHelper.setUser();
            }

            // get temporary invite token from header
            const url = new URL(window.location.href);

            const invite = url.searchParams.get('invite'); // 'node'

            if (invite)
                config.headers.invite = invite

            if (this.token && this.token !== 'undefined')
                config.headers.Authorization = `Bearer ${this.token}`;

            this.loading = true;
            return config
        });

        this.__axiosInstance.interceptors.response.use((response) => {
            this.loading = false
            return this.formatResponse(response)
        }, (error) => {
            // whatever you want to do with the error
            this.loading = false
            return this.errorHandler(error)
        });

    }


    formatResponse(response) {
        if (
            response.config.meta &&
            response.config.meta.toast &&
            response.config.meta.toast.message
        )
            Vue.$toast.open({
                type: "success",
                message: response.config.meta.toast.message,
                position: "top-right",
            });

        return response.data
    }

    errorHandler(error) {
        let message = error.message || 'Oops... Something went wrong';

        if (error.response.status === 401)
            // window.location.href = `${process.env.VUE_APP_SSO_UI}/sign-in`
            router.push('/sign-in')

        if (error.response && error.response.data)
            message = error.response.data.message;

        Vue.$toast.open({
            type: "error",
            message: message,
            position: "top-right",
        });

        throw error;
    }




}